import React, { useEffect, useState } from 'react';
import { InferGetServerSidePropsType } from 'next';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { isMobile } from 'pubweb-smokey/dist/utils/utils';
import { getHomepage } from '@services/contentfulService';
import { getSiteUrl } from '@utils/config';
import { getRandomInt } from '@utils/utils';
import LazyLoad from '@components/Shared/LazyLoad/LazyLoad';
import HeroImage from '@components/Home/HeroImage/HeroImage';
import AnkleCTA from '@components/Home/AnkleCTA/AnkleCTA';
import HowItWorks from '@components/Home/HowItWorks/HowItWorks';
import TestimonialsContainer from '@components/Home/Testimonials/TestimonialsContainer';
import WebForm from '@components/Shared/WebForm/WebForm';
import FeaturedHomes from '@components/Home/FeaturedHomes/FeaturedHomes';
import Overview from '@components/Home/Overview/Overview';
import { HomeStyles } from './Home.styled';
import { formatHttpsUrl } from '@utils/utils';

const ImageGrid = dynamic(
  () => import('@components/Home/ImageGrid/ImageGrid'),
  { ssr: false }
);

const HomeCTAVertical = dynamic(
  () => import('@components/Home/HomeCTAVertical/HomeCTAVertical'),
  { ssr: false }
);

const EmailSignupContainer = dynamic(
  // TODO: convert to TS
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('@components/Shared/EmailSignupContainer/EmailSignupContainer'),
  { ssr: false }
);

const Home = ({
  ssrHomepageContent,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const [emailSignup, setEmailSignup] = useState(false);
  const [homepageContent] = useState(ssrHomepageContent);
  const [heroIndex] = useState(
    homepageContent?.heroImage?.length
      ? getRandomInt(0, homepageContent.heroImage.length)
      : 0
  );
  const [isMobileLayout] = useState(isMobile());
  const router = useRouter();

  useEffect(() => {
    const timer = setTimeout(() => {
      setEmailSignup(true);
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const sectionCTA = () => (
    <LazyLoad offset={100} classNamePrefix="home-cta">
      <div className="home-cta">
        {homepageContent.contentCTAs && (
          <HomeCTAVertical ctaCollection={homepageContent.contentCTAs} />
        )}
      </div>
    </LazyLoad>
  );

  const sectionTestimonials = () => {
    const { testimonialSection = {} } = homepageContent;
    const {
      sectionStyle,
      sectionHeader,
      testimonials = [],
      testimonialLinkText,
      backgroundColor,
      sectionImage,
    } = testimonialSection;

    return testimonials.length > 0 ? (
      <LazyLoad offset={200} classNamePrefix="testimonials">
        <TestimonialsContainer
          sectionStyle={sectionStyle}
          sectionHeader={sectionHeader}
          testimonials={testimonials}
          linkText={testimonialLinkText}
          backgroundColor={backgroundColor}
          isMobile={isMobileLayout}
          backgroundImage={formatHttpsUrl(sectionImage?.file?.url)}
        />
      </LazyLoad>
    ) : null;
  };

  const sectionHowItWorks = () => {
    const { processSection } = homepageContent;
    if (processSection) {
      const { snippetSectionHeader, snippets, backgroundColor } =
        processSection;
      return (
        <LazyLoad offset={200} classNamePrefix="how-it-works">
          <HowItWorks
            snippetSectionHeader={snippetSectionHeader}
            snippets={snippets}
            backgroundColor={backgroundColor}
          />
        </LazyLoad>
      );
    }
  };

  const sectionInspiration = () => {
    const { imageGridSection } = homepageContent;
    const {
      sectionHeader,
      imageSource,
      images = [],
      buttonText,
      buttonURL,
    } = imageGridSection || {};

    return images.length > 0 ? (
      <LazyLoad offset={100} classNamePrefix="image-grid">
        <div className="image-grid">
          <ImageGrid
            imageSource={imageSource}
            sectionHeader={sectionHeader}
            images={images}
            buttonText={buttonText}
            buttonURL={buttonURL}
          />
        </div>
      </LazyLoad>
    ) : null;
  };

  const sectionFeaturedHomes = () => (
    <div className="featured-homes-section">
      <FeaturedHomes section={homepageContent.featuredHomesSection} />
    </div>
  );

  const sectionOverview = () => {
    const { overviewHeader = '', overview = '' } = homepageContent;
    return (
      <div className="overview-section">
        <Overview overviewHeader={overviewHeader} overviewText={overview} />
      </div>
    );
  };

  return (
    <>
      <Head>
        <title>{homepageContent.titleTag}</title>
        <meta
          name="google-site-verification"
          content="mTfGP6pvd73Okeb_cA-Pz0CJRCDsXuHxWb9bN---VIA"
        />
        <meta name="description" content={homepageContent.metaDescription} />
        <link rel="canonical" href={`${getSiteUrl(router.pathname)}`} />
        <meta
          property="og:image"
          content={
            typeof homepageContent.ogImage !== 'undefined' &&
            homepageContent.ogImage !== null
              ? homepageContent.ogImage.file.url
              : `${getSiteUrl('/public-assets/images/clayton-logo.jpg')}`
          }
        />
      </Head>
      {emailSignup ? (
        <EmailSignupContainer
          onCloseEmailPopup={() => {
            setEmailSignup(false);
          }}
        />
      ) : null}

      <HomeStyles>
        <div className="home-hero" key={`home-hero-${heroIndex}`}>
          <HeroImage
            heroImage={homepageContent?.heroImage?.[heroIndex]}
            mobileHeroImage={homepageContent?.mobileHeroImage?.[heroIndex]}
            heroCTA={homepageContent?.heroCTAs?.[0]}
            heroIndex={heroIndex}
            isMobileLayout={isMobileLayout}
          />
        </div>

        {[
          sectionFeaturedHomes(),
          sectionOverview(),
          sectionInspiration(),
          sectionHowItWorks(),
          sectionTestimonials(),
          sectionCTA(),
        ]}

        <LazyLoad offset={100} classNamePrefix="home-ankle-cta">
          <div className="home-ankle-cta">
            <AnkleCTA ctaData={homepageContent.preFooterCTA[0]} />
          </div>
        </LazyLoad>
        <LazyLoad classNamePrefix="home-form">
          <div className="home-form">
            <WebForm
              webFormData={{
                ...homepageContent.webForm,
                formActionType: 'submit',
                formName: 'homepage',
                formMethod: 'embedded',
                formID: 'email_subscribe_homepage',
              }}
            />
          </div>
        </LazyLoad>
      </HomeStyles>
    </>
  );
};

export async function getServerSideProps() {
  // TODO: specify a type here once getHomepage() is converted to TS
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let homepageContent: any = null;

  const homepageKey = '7M9nGRU5PoauhFmEEiKuJ5';
  await Promise.allSettled([
    getHomepage(homepageKey)
      .then((response) => {
        homepageContent = response;
      })
      .catch(() => {
        console.error(
          'no content!\tYou may need to run AWS Remote Worker. -or- Contentful is not responding.'
        );
      }),
  ]);

  return {
    props: { ssrHomepageContent: homepageContent },
  };
}

export default Home;
