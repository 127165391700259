export const allPriceChoicesMin = [
  { value: 25000, text: '$25,000' },
  { value: 50000, text: '$50,000' },
  { value: 75000, text: '$75,000' },
  { value: 100000, text: '$100,000' },
  { value: 125000, text: '$125,000' },
  { value: 150000, text: '$150,000' },
  { value: 175000, text: '$175,000' },
  { value: 200000, text: '$200,000' },
  { value: 225000, text: '$225,000' },
  { value: 250000, text: '$250,000' },
  { value: 275000, text: '$275,000' },
  { value: 300000, text: '$300,000' },
  { value: 325000, text: '$325,000' },
  { value: 350000, text: '$350,000' },
  { value: 375000, text: '$375,000' },
];
export const allPriceChoicesMax = [
  { value: 50000, text: '$50,000' },
  { value: 75000, text: '$75,000' },
  { value: 100000, text: '$100,000' },
  { value: 125000, text: '$125,000' },
  { value: 150000, text: '$150,000' },
  { value: 175000, text: '$175,000' },
  { value: 200000, text: '$200,000' },
  { value: 225000, text: '$225,000' },
  { value: 250000, text: '$250,000' },
  { value: 275000, text: '$275,000' },
  { value: 300000, text: '$300,000' },
  { value: 325000, text: '$325,000' },
  { value: 350000, text: '$350,000' },
  { value: 375000, text: '$375,000' },
  { value: 800000, text: '$400,000+' },
];
export const DEFAULT_MIN_PRICE = allPriceChoicesMin[0].value;
export const DEFAULT_MAX_PRICE =
  allPriceChoicesMax[allPriceChoicesMax.length - 1].value;

export const bedItems = [
  { value: 1, text: '1+' },
  { value: 2, text: '2+' },
  { value: 3, text: '3+' },
  { value: 4, text: '4+' },
  { value: 5, text: '5+' },
];
export const bathItems = [
  { value: 1, text: '1+' },
  { value: 2, text: '2+' },
  { value: 3, text: '3+' },
];
export const DEFAULT_BEDS = bedItems[0].value;
export const DEFAULT_BATHS = bathItems[0].value;

export const allMinSquareFeet = [
  { value: 0, text: '0 sq. ft.' },
  { value: 500, text: '500 sq. ft.' },
  { value: 750, text: '750 sq. ft.' },
  { value: 1000, text: '1000 sq. ft.' },
  { value: 1250, text: '1250 sq. ft.' },
  { value: 1500, text: '1500 sq. ft.' },
  { value: 1750, text: '1750 sq. ft.' },
  { value: 2000, text: '2000 sq. ft.' },
  { value: 2500, text: '2500 sq. ft.' },
  { value: 3000, text: '3000 sq. ft.' },
  { value: 3500, text: '3500 sq. ft.' },
  { value: 4000, text: '4000 sq. ft.' },
  { value: 4500, text: '4500 sq. ft.' },
  { value: 5000, text: '5000 sq. ft.' },
  { value: 5500, text: '5500 sq. ft.' },
];
export const allMaxSquareFeet = [
  { value: 500, text: '500 sq. ft.' },
  { value: 750, text: '750 sq. ft.' },
  { value: 1000, text: '1000 sq. ft.' },
  { value: 1250, text: '1250 sq. ft.' },
  { value: 1500, text: '1500 sq. ft.' },
  { value: 1750, text: '1750 sq. ft.' },
  { value: 2000, text: '2000 sq. ft.' },
  { value: 2500, text: '2500 sq. ft.' },
  { value: 3000, text: '3000 sq. ft.' },
  { value: 3500, text: '3500 sq. ft.' },
  { value: 4000, text: '4000 sq. ft.' },
  { value: 4500, text: '4500 sq. ft.' },
  { value: 5000, text: '5000 sq. ft.' },
  { value: 5500, text: '5500 sq. ft.' },
  { value: 6000, text: '6000 sq. ft.' },
];
export const DEFAULT_MIN_SQ_FT = allMinSquareFeet[0].value;
export const DEFAULT_MAX_SQ_FT =
  allMaxSquareFeet[allMaxSquareFeet.length - 1].value;

export type Section = 'all' | 'single' | 'multi' | 'crossmod';
export const allSections: Section[] = ['all', 'single', 'multi', 'crossmod'];
export const DEFAULT_SECTION = 'all';

// 1 - Homes in stock
// 2 - Lowest
// 3 - Highest
// 4 - Featured
export const allSortMethodIds = [1, 2, 3, 4];
export const DEFAULT_SORT_METHOD_ID = 4;

export const modelTypeIds = [1, 2];
export const ALL_MODELS_TYPE_ID = 1;
export const MOVE_IN_READY_TYPE_ID = 2;

export const allAvailableFeatures = [
  'Office',
  'Primary Bedroom Retreat',
  'Nursery',
  'Family Room',
  'Bonus Room',
  'Computer Nook',
  'Utility Room',
  'Cooking Island',
  'Dining Room',
  'Covered Entry',
  'Split Bedrooms',
  'Two Story',
  'Open Floor Plan',
  '1 1/2 Story',
  'Kitchen Island',
  'Breakfast Bar',
  'Entertainment Unit',
  'Fireplace',
  'Computer Station',
  'Pantry',
  'Double Primary Closets',
  'Double Sinks in Bath',
  'Vaulted Ceilings',
  'Separate Tub and Shower',
  'Foyer',
  'Drywall',
  'Modular Construction',
  "9' Ceilings",
  'Covered Porch',
  'Garage',
  'Tray Ceilings',
];
