import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { getModelsByModelNumbers, getHomes } from '@services/homesService';
import { LocationsContext } from '@contexts/LocationsContext';

import { FeaturedHomeStyles } from './FeaturedHomes.styled';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import HomesCarousel from './HomesCarousel/HomesCarousel';

import LoadingIndicator from '@components/Shared/LoadingIndicator/LoadingIndicator';
import Button from '@components/Shared/Buttons/Button';
import ChevronDwnSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/chevron-dwn.svg';
import { getBaseAwsApiUrl } from '@utils/config';
import { pushGTMEvent } from 'pubweb-smokey/dist/utils/analytics';
import LinkWrapper from '../../LinkWrapper/LinkWrapper';

const LocationSelector = dynamic(
  () => import('@components/Shared/LocationSelector/LocationSelector'),
  { ssr: false }
);

const FeaturedHomes = ({
  section,
  headlineLinkUrl,
  headlineLinkClassName,
  isHomePage = true,
  includeModelsWithoutDealers = false,
  homeDetailsPage = null,
}) => {
  const locationsContext = useContext(LocationsContext);

  const [featuredHomes, setFeaturedHomes] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [showLocationSelector, setShowLocationSelector] = useState(false);
  const [zipCodeMessage, setZipCodeMessage] = useState(null);

  function addDomainUrlToImages(homeModels) {
    // The home models returned by the query did not have a complete URL for the images.
    // I looked at the image URL for a home returned by getModelByModelNumber and noticed
    // it was prefixed with the AWS API URL plus "images/". So I go in and prefix the image
    // URLs with that information to make them work.

    const imgBaseUrl = getBaseAwsApiUrl() + 'images/';
    return homeModels.map((m) => {
      for (let i = 0; i < m.images.length; i++) {
        // does not start with http or https
        if (m.images[i].reference.search(/http/i) === -1) {
          m.images[i].reference = imgBaseUrl.concat(m.images[i].reference);
        }
      }

      // Look for an exterior view in the images. If found, make it the first image displayed.
      const extImg = m.images.find((e) => e.imageType === 1);
      if (extImg) {
        const idx = m.images.indexOf(extImg);
        //  idx is -1 if none found and 0 is already the first image.
        if (idx > 0) {
          m.images.splice(idx, 1);
          m.images.splice(0, 0, extImg);
        }
      }

      return m;
    });
  }

  async function getModels(modelNumbers, includeModelsWithoutDealers) {
    let models = [];
    const { geocode } = locationsContext.state;
    const { distance } = locationsContext.state;
    const modelSearchIncrement = 10;
    const desiredNumModels = 9;

    setUpdating(true);

    // Had to call getModelsByModelNumbers with a sub-set of model numbers because if you use too many
    // it will get a CORS error becaus the query string is too long. I believe this is interpreted as
    // a security risk so it gets rejected.
    for (let i = 0; i < modelNumbers.length; i += modelSearchIncrement) {
      let srchNums = modelNumbers.slice(i, i + modelSearchIncrement);

      await getModelsByModelNumbers(
        srchNums,
        geocode.latitude,
        geocode.longitude,
        distance
      ).then((results) => {
        // Sometimes we only want models that include dealers for the area (e.g. featured homes on homepage)
        // other times we don't mind if there are no dealers (e.g. featured homes on Stories page 'as seen on tiktok').
        if (includeModelsWithoutDealers) {
          models.push(...results);
        } else {
          // I noticed for places without any homes (e.g. Anchorage, AK), some homes would
          // be returned except they did not have any dealers. I started looking at the
          // dealer list and only include the home if it has some dealers listed.
          const withDealers = results.filter(
            (m) => m.dealers && m.dealers.length > 0
          );
          if (withDealers.length > 0) {
            models.push(...withDealers);
          }
        }
      });

      // Stop looking after we find at least the max desiredNumModels of homes.
      if (models.length >= desiredNumModels) break;
    }

    // Limit the list to max desired number of homes.
    if (models.length > desiredNumModels) {
      models = models.slice(0, desiredNumModels);
    }

    // Save the models and indicate we are finished with the update.
    setFeaturedHomes(addDomainUrlToImages(models));
    setUpdating(false);
  }

  async function getModelsBasedOnZip() {
    setUpdating(true);

    const numberOfItemsPerPage = 9;
    const startingIndex = 1;
    const modelTypeId = undefined;
    const features = undefined;
    await getHomes(
      homeDetailsPage.minSquareFeet,
      homeDetailsPage.maxSquareFeet,
      homeDetailsPage.baths,
      homeDetailsPage.beds,
      locationsContext.state.distance,
      locationsContext.state.geocode?.latitude,
      locationsContext.state.geocode?.longitude,
      homeDetailsPage.minPrice,
      homeDetailsPage.maxPrice,
      homeDetailsPage.isMultiSection,
      homeDetailsPage.isCrossMod,
      homeDetailsPage.sortId,
      numberOfItemsPerPage,
      startingIndex,
      modelTypeId,
      features
    )
      .then((result) => {
        if (!result) {
          return;
        }
        result.models.forEach((home) => {
          // NOTE: This is different than in the useGetHomes() hook. The HomesCarousel component takes images not thumbnailImages.
          home.images = [...home.thumbnailImages, ...home.images];
          // home.squareFeet = home.maxSquareFeet;
          const extImg = home.thumbnailImages.find((e) => e.imageType === 1);

          // Look for an exterior view in the images. If found, make it the first image displayed.
          if (extImg) {
            const idx = home.thumbnailImages.indexOf(extImg);
            //  idx is -1 if none found and 0 is already the first image.
            if (idx > 0) {
              home.thumbnailImages.splice(idx, 1);
              home.thumbnailImages.splice(0, 0, extImg);
            }
          }
        });

        // We don't want the same model in the list of featured homes. If we know the model number.
        result.models = result.models.filter(
          (h) => h.modelNumber != homeDetailsPage.modelNumber
        );

        // Limit the list to max desired number of homes.
        if (result.models.length > 0) {
          result.models = result.models.slice(0, 3);
        }

        setFeaturedHomes(addDomainUrlToImages(result.models));
        setUpdating(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (locationsContext?.state?.geocode && section?.modelNumberList) {
      // Note: the flatmap and split regex for spaces because the list in Contentful had two model numbers that were not comma separated.
      // If it happend once it could happen again.
      const modelNumbers = section?.modelNumberList
        .trim()
        .split(',')
        .flatMap((m) => {
          return m.trim().split(/\s+/);
        });
      getModels(modelNumbers, includeModelsWithoutDealers);
    } else if (homeDetailsPage) {
      getModelsBasedOnZip();
    }
  }, [
    locationsContext.state.geocode?.latitude,
    locationsContext.state.geocode?.longitude,
    locationsContext.state.distance,
    homeDetailsPage,
  ]);

  useEffect(() => {
    if (
      locationsContext.state.isLocationCallCompleted &&
      (!locationsContext.state || !locationsContext.state.postalCode)
    ) {
      setZipCodeMessage('Please provide a zip code');
    } else if (
      locationsContext.state.isLocationCallCompleted &&
      locationsContext.state.postalCode
    ) {
      setZipCodeMessage(null);
    }
  }, [locationsContext.state]);

  const getSectionTitle = ({ txt }) => {
    return (
      <p className={isHomePage ? 'h3 headline' : 'h3 headline-left'}>{txt}</p>
    );
  };

  const getSectionHeader = ({ txt, url, className }) => {
    return !url || url === '' ? (
      getSectionTitle({ txt })
    ) : (
      <LinkWrapper className={`headline-link ${className}`} url={url}>
        {getSectionTitle({ txt })}
      </LinkWrapper>
    );
  };

  return (
    <FeaturedHomeStyles $isZipError={zipCodeMessage ? true : false}>
      {getSectionHeader({
        txt: section?.sectionHeader || (homeDetailsPage ? 'More Homes' : ''),
        url: headlineLinkUrl,
        className: headlineLinkClassName,
      })}
      {isHomePage && (
        <div className="featured-location-wrap">
          <LocationsContext.Consumer>
            {({ state, actions }) => {
              return (
                <>
                  <p className="featured-location" id="featured-loc">
                    {zipCodeMessage
                      ? zipCodeMessage + ' '
                      : 'Show homes within '}
                    <a
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setShowLocationSelector(true);
                        pushGTMEvent(
                          'ch.featHomesZipModalOpenClick',
                          'featured_home_zip_open',
                          {
                            location: 'Homepage',
                            category: 'home_search',
                            action: 'text',
                            label: 'ch.featHomesZipModalOpenClick',
                          }
                        );
                        // If the location selector dialog was opened too close to the bottom, it was not able to be fully displayed.
                        const el = document.getElementById('featured-loc');
                        if (el && typeof window !== 'undefined') {
                          if (
                            el.getBoundingClientRect().bottom + 75 >
                            window.innerHeight
                          ) {
                            window.scrollTo({
                              top: window.pageYOffset + 75,
                            });
                          }
                        }
                      }}
                      suppressHydrationWarning={true}
                    >
                      {zipCodeMessage ? (
                        <p className="emptySpace"> </p>
                      ) : (
                        '  ' + state.distance + ' miles of ' + state.postalCode
                      )}
                      <span>
                        <ChevronDwnSvg />
                      </span>
                    </a>
                  </p>
                  {showLocationSelector && (
                    <LocationSelector
                      locationKey="featured-homes"
                      postalCode={state.postalCode}
                      distance={state.distance}
                      onUpdate={actions.updateLocation}
                      setUpdating={actions.setUpdating}
                      featuredHomes={true}
                      onCloseClick={() => {
                        setShowLocationSelector(false);
                      }}
                    />
                  )}
                </>
              );
            }}
          </LocationsContext.Consumer>
        </div>
      )}
      {updating && (
        <div className="loading-indicator">
          <LoadingIndicator width="64" height="64" />
        </div>
      )}
      {!updating && (
        <>
          {featuredHomes?.length > 0 ? (
            <HomesCarousel
              homes={featuredHomes}
              ctas={section?.featuredCTAs}
              isHomePage={isHomePage}
            />
          ) : (
            <div className="h4 no-featured-homes">
              Expand your search radius or view all homes.
            </div>
          )}
        </>
      )}

      {isHomePage && (
        <div
          className="featured-buttons"
          onClick={() =>
            pushGTMEvent('ch.featuredHomesCTAClick1', 'featured_home_cta1', {
              location: 'Homepage',
              category: 'home_search',
              action: 'button',
              label: 'ch.featuredHomesCTAClick1',
            })
          }
        >
          <Link href="/find-a-home/">
            <Button
              className="clayton-blue button"
              isCompact={false}
              text={homeDetailsPage ? 'Search Homes' : 'View All Homes'}
              automationId="view-all-homes-button"
            />
          </Link>
        </div>
      )}
    </FeaturedHomeStyles>
  );
};

export default FeaturedHomes;

FeaturedHomes.propTypes = {
  modelNumberList: PropTypes.string,
  section: PropTypes.object,
  headlineLinkUrl: PropTypes.string,
  headlineLinkClassName: PropTypes.string,
  isHomePage: PropTypes.bool,
  includeModelsWithoutDealers: PropTypes.bool,
  homeDetailsPage: PropTypes.object,
};
